import React,{useEffect, useState} from 'react'
import { graphql } from 'gatsby'
import {Layout, TextPage, Seo} from '../components'

const WinePage = ({data}) => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    return (
        <Seo title={'On Premise Wine Crafting - The Process'}>
            <Layout>
                <TextPage image={data.datoCmsWineMaking.image} structuredText={data.datoCmsWineMaking.article} to={'/wine-making'}/>
            </Layout>
        </Seo>
    )
}

export default WinePage

export const query = (graphql`
query {
    datoCmsWineMaking {
        image{
            sizes(maxWidth: 1920, imgixParams: { fm: "webp" }) {
                ...GatsbyDatoCmsSizes
            }
        }
        article {
            value
        }
    }
}
`)